/* list of named constants for operations and mutations used by the app store

   example:

   export const CREATE_ASSISTANT = 'CREATE_ASSISTANT'
   ...

 */

// assistant
export const CREATE_CHI_ASSISTANT = "CREATE_CHI_ASSISTANT";
export const LOAD_CHI_ASSISTANT = "LOAD_CHI_ASSISTANT";
export const PATCH_CHI_ASSISTANT = "PATCH_CHI_ASSISTANT";
// threads
export const LIST_CHI_ASSISTANT_THREADS = "LIST_CHI_ASSISTANT_THREADS";
export const CREATE_CHI_ASSISTANT_THREAD = "CREATE_CHI_ASSISTANT_THREAD";
export const DELETE_CHI_ASSISTANT_THREAD = "DELETE_CHI_ASSISTANT_THREAD";
export const LOAD_CHI_ASSISTANT_THREAD = "LOAD_CHI_ASSISTANT_THREAD";
export const LIST_CHI_ASSISTANT_THREAD_MESSAGES =
    "LIST_CHI_ASSISTANT_THREAD_MESSAGES";
export const PUBLISH_CHI_ASSISTANT = "PUBLISH_CHI_ASSISTANT";
export const RETRACT_CHI_ASSISTANT = "RETRACT_CHI_ASSISTANT";
export const DELETE_CHI_ASSISTANT = "DELETE_CHI_ASSISTANT";
export const PUBLISH_CHI_ASSISTANT_THREAD = "PUBLISH_CHI_ASSISTANT_THREAD";
export const RETRACT_CHI_ASSISTANT_THREAD = "RETRACT_CHI_ASSISTANT_THREAD";
export const RUN_ASSISTANT_WITH_MESSAGE = "RUN_ASSISTANT_WITH_MESSAGE";
// workshop

// Quantum HR Assistant
export const FIND_JOBS_BY_SKILLS = "FIND_JOBS_BY_SKILLS";
// vector store
export const CREATE_CHI_VECTOR_STORE = "CREATE_CHI_VECTOR_STORE";
export const LOAD_CHI_VECTOR_STORE = "LOAD_CHI_VECTOR_STORE";
export const PATCH_CHI_VECTOR_STORE = "PATCH_CHI_VECTOR_STORE";
export const DELETE_CHI_VECTOR_STORE = "DELETE_CHI_VECTOR_STORE";
export const ADD_CHI_VECTOR_STORE_FILES = "ADD_CHI_VECTOR_STORE_FILES";
export const PATCH_CHI_VECTOR_STORE_FILE = "PATCH_CHI_VECTOR_STORE_FILE";
export const REPLACE_CHI_VECTOR_STORE_FILE = "REPLACE_CHI_VECTOR_STORE_FILE";
export const DELETE_CHI_VECTOR_STORE_FILE = "DELETE_CHI_VECTOR_STORE_FILE";
export const STREAM_CHI_VECTOR_STORE_MODIFICATION_STATE =
    "STREAM_CHI_VECTOR_STORE_MODIFICATION_STATE";
// file
export const PATCH_CHI_FILE = "PATCH_CHI_FILE";
export const BATCH_IMPORT_ASSISTANT_FILES = "BATCH_IMPORT_ASSISTANT_FILES";
// create: upload to /upload/assistants/:id
export const DELETE_CHI_ASSISTANT_FILE = "DELETE_CHI_ASSISTANT_FILE";
