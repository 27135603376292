import {
    CREATE_CHI_ASSISTANT,
    CREATE_CHI_ASSISTANT_THREAD,
    DELETE_CHI_ASSISTANT,
    DELETE_CHI_ASSISTANT_FILE,
    DELETE_CHI_ASSISTANT_THREAD,
    LIST_CHI_ASSISTANT_THREAD_MESSAGES,
    LOAD_CHI_ASSISTANT,
    LOAD_CHI_ASSISTANT_THREAD,
    PATCH_CHI_ASSISTANT,
    PUBLISH_CHI_ASSISTANT,
    PUBLISH_CHI_ASSISTANT_THREAD,
    RETRACT_CHI_ASSISTANT,
    RETRACT_CHI_ASSISTANT_THREAD,
    LIST_CHI_ASSISTANT_THREADS,
    RUN_ASSISTANT_WITH_MESSAGE,
} from '../operations.js'
import { _RESET_STATUS } from '@/store/operations.js'
import { throttleBufferedAction } from '@/store/lib/throttledBufferedAction'
import axios from '@/plugins/axios'
import { handleResponse } from '@/store/actions/lib/handleResponse'
import { getDataTypeActionUrl } from '@/store/actions/lib/getDataTypeActionUrl.js'
import { getDocumentActionUrl } from '@/store/actions/lib/getDocumentActionUrl.js'
import { isRef } from 'vue'
import { isString } from '@mehimself/cctypehelpers'

const _appId = 'assistants'
export default {
    [CREATE_CHI_ASSISTANT](context, attributes) {
        context.commit(_RESET_STATUS, CREATE_CHI_ASSISTANT)
        let url = getDataTypeActionUrl({
            _appId,
            _dataType: 'Assistant',
            endpoint: 'create',
        })
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: {
                ...attributes,
            },
        }).then(handleResponse(context, CREATE_CHI_ASSISTANT))
    },
    [LOAD_CHI_ASSISTANT](context, { _id }) {
        context.commit(_RESET_STATUS, LOAD_CHI_ASSISTANT)
        let url = getDocumentActionUrl({
            _appId,
            _dataType: 'Assistant',
            _id,
            endpoint: 'load',
        })
        return throttleBufferedAction({
            action: axios.post,
            url,
        }).then(handleResponse(context, LOAD_CHI_ASSISTANT))
    },
    [PATCH_CHI_ASSISTANT](context, { _id, attributes }) {
        context.commit(_RESET_STATUS, PATCH_CHI_ASSISTANT)
        let url = getDocumentActionUrl({
            _appId,
            _dataType: 'Assistant',
            _id,
            endpoint: 'patch',
        })
        return throttleBufferedAction({
            action: axios.patch,
            url,
            params: {
                update: attributes,
                assistantId: _id,
            },
        }).then(handleResponse(context, PATCH_CHI_ASSISTANT))
    },
    [PUBLISH_CHI_ASSISTANT](context, { _id }) {
        context.commit(_RESET_STATUS, PUBLISH_CHI_ASSISTANT)
        let url = getDocumentActionUrl({
            _appId,
            _dataType: 'Assistant',
            _id,
            endpoint: 'publish',
        })
        return throttleBufferedAction({
            action: axios.post,
            url,
        }).then(handleResponse(context, PUBLISH_CHI_ASSISTANT))
    },
    [RETRACT_CHI_ASSISTANT](context, { _id }) {
        context.commit(_RESET_STATUS, RETRACT_CHI_ASSISTANT)
        let url = getDocumentActionUrl({
            _appId,
            _dataType: 'Assistant',
            _id,
            endpoint: 'retract',
        })
        return throttleBufferedAction({
            action: axios.post,
            url,
        }).then(handleResponse(context, RETRACT_CHI_ASSISTANT))
    },
    [DELETE_CHI_ASSISTANT](context, { _id }) {
        context.commit(_RESET_STATUS, DELETE_CHI_ASSISTANT)
        let url = getDocumentActionUrl({
            _appId,
            _dataType: 'Assistant',
            _id,
            endpoint: 'delete',
        })
        return throttleBufferedAction({
            action: axios.delete,
            url,
        }).then(handleResponse(context, DELETE_CHI_ASSISTANT))
    },
    [LIST_CHI_ASSISTANT_THREADS](context, { _assistantId }) {
        // deprecated: use list documents instead
        context.commit(_RESET_STATUS, LIST_CHI_ASSISTANT_THREADS)
        let url = getDataTypeActionUrl({
            _appId,
            _dataType: 'Thread',
            endpoint: 'list',
        })
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: {
                _assistantId,
            },
        }).then(handleResponse(context, LIST_CHI_ASSISTANT_THREADS))
    },
    [PUBLISH_CHI_ASSISTANT_THREAD](context, { _assistantId, thread_id }) {
        // deprecated: use publish document instead
        context.commit(_RESET_STATUS, PUBLISH_CHI_ASSISTANT_THREAD)
        let url = getDocumentActionUrl({
            _appId,
            _dataType: 'Thread',
            _id: thread_id,
            endpoint: 'publish',
        })
        return throttleBufferedAction({
            action: axios.put,
            url,
            params: {
                _assistantId,
            },
        }).then(handleResponse(context, PUBLISH_CHI_ASSISTANT_THREAD))
    },
    [RETRACT_CHI_ASSISTANT_THREAD](context, { _assistantId, thread_id }) {
        // deprecated: use retract document instead
        context.commit(_RESET_STATUS, RETRACT_CHI_ASSISTANT_THREAD)
        let url = getDocumentActionUrl({
            _appId,
            _dataType: 'Thread',
            _id: thread_id,
            endpoint: 'retract',
        })
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: {
                _assistantId,
            },
        }).then(handleResponse(context, RETRACT_CHI_ASSISTANT_THREAD))
    },
    [CREATE_CHI_ASSISTANT_THREAD](context, { _assistantId }) {
        context.commit(_RESET_STATUS, CREATE_CHI_ASSISTANT_THREAD)
        let url = getDataTypeActionUrl({
            _appId,
            _dataType: 'Thread',
            endpoint: 'create',
        })
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: {
                _assistantId,
            },
        }).then(handleResponse(context, CREATE_CHI_ASSISTANT_THREAD))
    },
    [DELETE_CHI_ASSISTANT_THREAD](context, { _assistantId, thread_id }) {
        context.commit(_RESET_STATUS, DELETE_CHI_ASSISTANT_THREAD)
        let url = getDocumentActionUrl({
            _appId,
            _dataType: 'Thread',
            _id: thread_id,
            endpoint: 'delete',
        })
        return throttleBufferedAction({
            action: axios.delete,
            url,
            params: {
                _assistantId,
            },
        }).then(handleResponse(context, DELETE_CHI_ASSISTANT_THREAD))
    },
    [DELETE_CHI_ASSISTANT_FILE](context, { _fileId }) {
        context.commit(_RESET_STATUS, DELETE_CHI_ASSISTANT_FILE)
        let url = getDocumentActionUrl({
            _appId,
            _dataType: 'File',
            _id: _fileId,
            endpoint: 'delete',
        })
        return throttleBufferedAction({
            action: axios.delete,
            url,
        }).then(handleResponse(context, DELETE_CHI_ASSISTANT_FILE))
    },
    [RUN_ASSISTANT_WITH_MESSAGE](
        context,
        {
            pipeName,
            listenerName,
            _assistantId,
            thread_id,
            message,
            messageDocumentIds = [],
            sender,
        }
    ) {
        context.commit(_RESET_STATUS, RUN_ASSISTANT_WITH_MESSAGE)
        if (isRef(messageDocumentIds))
            messageDocumentIds = messageDocumentIds.value
        messageDocumentIds = messageDocumentIds || []
        messageDocumentIds = messageDocumentIds.filter(
            (id) => isString(id) && id.length > 0
        )
        return throttleBufferedAction({
            action: axios.post,
            url: getDocumentActionUrl({
                _appId,
                _dataType: 'Assistant',
                _id: _assistantId,
                endpoint: 'runWithMessage',
            }),
            params: {
                pipeName,
                listenerName,
                thread_id,
                message, // string
                messageDocumentIds,
                sender, // anonymous || 'userId_accountFirstName accountLastName'
            },
        }).then(handleResponse(context, RUN_ASSISTANT_WITH_MESSAGE))
    },
    [LIST_CHI_ASSISTANT_THREAD_MESSAGES](context, { _assistantId, thread_id }) {
        context.commit(_RESET_STATUS, LIST_CHI_ASSISTANT_THREAD_MESSAGES)
        let url = getDocumentActionUrl({
            _appId,
            _dataType: 'Thread',
            _id: thread_id,
            endpoint: 'listMessages',
        })
        return axios
            .post(url)
            .then(handleResponse(context, LIST_CHI_ASSISTANT_THREAD_MESSAGES))
    },
    [LOAD_CHI_ASSISTANT_THREAD](context, { _appId, thread_id }) {
        context.commit(_RESET_STATUS, LOAD_CHI_ASSISTANT_THREAD)
        let url = getDocumentActionUrl({
            _appId,
            _dataType: 'Thread',
            _id: thread_id,
            endpoint: 'get', // listRuns
        })
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: {
                _appId,
            },
        }).then(handleResponse(context, LOAD_CHI_ASSISTANT_THREAD))
    },
    // [BATCH_IMPORT_ASSISTANT_FILES](context, {entryPath, fileNamePrefix}) {
    //     context.commit(_RESET_STATUS, BATCH_IMPORT_ASSISTANT_FILES)
    //     let url = getApiUrl() + '/batchImportAssistantFiles/'
    //     return throttleBufferedAction({
    //         action: axios.post,
    //         url,
    //         params: {
    //             entryPath,
    //             fileNamePrefix,
    //         }
    //     })
    //         .then(handleResponse(context, BATCH_IMPORT_ASSISTANT_FILES))
    // }
}
