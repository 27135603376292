import {
    LIST_ASSETS,
    GET_ASSET,
    DELETE_ASSET,
    LOGIN,
    LOGOUT,
    RESUME_SESSION,
    _PUT_STATUS,
    _RESET_ASSETS,
    _SET_UI_STATE,
    _RESET_STATUS,
    SYNC_ASSETS,
    APPLY_DELTA,
} from './operations.js'

import { isStatusVariable, useDataType } from '@/store/lib/assets.js'
const resetDocumentStore = (state) => {
    state.docs = {}
}
const upsertLocalAssets = (state, payload, resetState) => {
    // payload is an object with keys as dataTypes and values as arrays of asset deltas
    // payload: {_dataType: [asset1Delta, asset2Delta, ...]}
    return Object.keys(payload)
        .filter((key) => !isStatusVariable(key))
        .forEach((_dataType) =>
            useDataType(state, _dataType, true).synchronizeState(
                payload[_dataType],
                resetState
            )
        )
}
const applyDelta = (state, delta) => {
    return useDataType(state, delta._dataType, true).applyAssetDelta(delta)
}
const onLogin = (state, { User, Account, _isDevOp, _isAppAdmin }) => {
    resetDocumentStore(state)
    state.user = User?.[0] ?? null
    if (state.user && _isDevOp) state.user._isDevop = _isDevOp
    if (state.user && (_isDevOp || _isAppAdmin))
        state.user._isAppAdmin = _isDevOp || _isAppAdmin
    state.account = Account?.[0] ?? null
    state.roleDebounce = Date.now() + 1000 * 60 * 2
    localStorage.setItem('user', JSON.stringify(state.user))
    localStorage.setItem('account', JSON.stringify(state.account))
}
const onLogout = (state) => {
    resetDocumentStore(state)
    state.user = null
    state.account = null
    delete state.roleDebounce
    localStorage.removeItem('user', null)
    localStorage.removeItem('account', null)
}
export default {
    [LIST_ASSETS]: upsertLocalAssets,
    [SYNC_ASSETS]: upsertLocalAssets,
    [APPLY_DELTA]: applyDelta,
    [GET_ASSET]: upsertLocalAssets,
    [DELETE_ASSET]: upsertLocalAssets,
    [RESUME_SESSION](state, data) {
        if (data?.User) {
            onLogin(state, data)
        } else {
            onLogout(state)
        }
    },
    [LOGIN]: onLogin,
    [LOGOUT]: onLogout,
    [_SET_UI_STATE](state, UIState) {
        Object.assign(state.UI, UIState)
    },
    [_PUT_STATUS](state, { event, result }) {
        const error = result?.error ?? result?.Error

        let status = null
        if (error) {
            status = {
                isError: true,
                body: error.message,
                timestamp: Date.now(),
            }
        } else if (result?._status) {
            status = {
                isSuccess: !result._isError,
                isError: result._isError,
                body: result._status,
                timestamp: Date.now(),
            }
        }
        if (status) {
            // console.log(33, event, error, status)
            state.status[event] = status
        }
    },
    [_RESET_STATUS](state, event) {
        delete state.status[event]
    },
    [_RESET_ASSETS](state) {
        Object.keys(state.docs).forEach((key) => {
            state.docs[key] = {}
        })
    },
}
