import { assertIsSupportedOperation } from '@/store/lib/veriffyOperation'
import { areSame, isArrayOfStrings, isObject } from '@mehimself/cctypehelpers'
import { normalizeDocumentOperationKeys } from '@/composables/document/lib/normalizeDocumentOperationKeys'
import {
    CREATE_DOCUMENT,
    LIST_DOCUMENTS,
} from '@/store/operations/documentOperations'
import { isJSIdentifier } from '@mehimself/cctypehelpers'
import {
    computed,
    getCurrentInstance,
    isRef,
    onMounted,
    reactive,
    ref,
    toRefs,
    watch,
} from 'vue'
import { useStore } from 'vuex'
import { filterDocuments } from '@/composables/document/lib/filterDocuments'
import { ingestFilterRef } from '@/composables/document/lib/ingestFilterRef.js'

const debug = {
    list: false,
    create: false,
}

const verifyOperations = (operations) => {
    Object.values(operations).forEach(assertIsSupportedOperation)
}

const ingestOperations = (operations) => {
    operations = operations || {}
    if (!isObject(operations)) throw new Error('operations must be an object')

    normalizeDocumentOperationKeys(operations)

    if (!operations.listDocuments) operations.listDocuments = LIST_DOCUMENTS
    if (!operations.createDocument) operations.createDocument = CREATE_DOCUMENT

    verifyOperations(operations)
    return operations
}
const ingestGenerators = (generators) => {
    generators = generators || {}
    if (!isObject(generators)) throw new Error('generators must be an object')
    return generators
}
const ingestAlias = (alias) => {
    if (!isJSIdentifier(alias))
        throw new Error('alias must be a valid JavaScript identifier')
    return alias
}
const ingestOptions = (options) => {
    options = options || {}
    if (!isObject(options)) throw new Error('options must be an object')
    return options
}
const ingestProperties = (properties) => {
    properties = properties || {}
    if (!isObject(properties)) throw new Error('properties must be an object')
    if (Object.keys(properties).some((key) => key.startsWith('_')))
        throw new Error(
            'properties must not contain keys that start with an underscore'
        )
    return properties
}
const ingestOffset = (offsetRef) => {
    offsetRef = offsetRef || 0
    if (!isRef(offsetRef)) offsetRef = ref(offsetRef)
    offsetRef.value = offsetRef.value || 0
    return offsetRef
}
const ingestSorting = (sortingRef) => {
    sortingRef = sortingRef || ref([])
    if (!isRef(sortingRef)) sortingRef = ref(sortingRef)
    if (!isArrayOfStrings(sortingRef.value))
        throw new Error(
            'sorting must be an array of strings fx. "_createdAt, desc"'
        )
    sortingRef.value.forEach((sort) => {
        const [attributeName, direction = 'desc'] = sort
            .split(',')
            .map((s) => s.trim())
        if (!['asc', 'desc'].includes(direction.toLowerCase()))
            throw new Error('sorting direction must be "asc" or "desc"')
        if (!isJSIdentifier(attributeName))
            throw new Error(
                attributeName,
                'is not a valid attributeName for sorting. Must be a valid JavaScript identifier.'
            )
    }, true)
    return sortingRef
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const useDocuments = ({
    alias,
    _appId,
    _dataType,
    proxyId,
    operations,
    generators,
    filter,
    sorting,
    offset,
    pageSize,
    options,
}) => {
    try {
        if (!_appId) throw new Error('_appId is required')
        if (!_dataType) throw new Error('_dataType is required')

        offset = ingestOffset(offset)
        pageSize = pageSize || 24
        filter = ingestFilterRef({ _appId, _dataType, filter })
        sorting = ingestSorting(sorting)

        alias = ingestAlias(alias)
        operations = ingestOperations(operations)
        generators = ingestGenerators(generators)
        options = ingestOptions(options)
    } catch (err) {
        console.warn(487, getCurrentInstance()?.type?.name, err)
        if (process.env.NODE_ENV === 'development') throw err
    }

    const { autoLoad = false } = options

    const store = useStore()
    const documents = ref([])
    const context = reactive({
        [alias]: documents,
        [`${alias}Page`]: [],
        isWaitingForFilter: true,
        isLoaded: false,
        isLoading: false,
        isPristine: false,
        error: null,
    })

    store.state.docs[_dataType] = store.state.docs?.[_dataType] || {}

    const requestDocumentPage = (
        { _filter, _offset, pageIsOutdated } = { pageIsOutdated: false }
    ) => {
        try {
            // merge overrides context values
            _filter = _filter || filter
            if (!isRef(_filter)) _filter = ref(_filter)
            _filter = ingestFilterRef({
                _appId,
                _dataType,
                filter: _filter,
            })
            _offset = _offset ?? offset
            if (isRef(_offset)) _offset = _offset.value

            if (autoLoad || pageIsOutdated) {
                context.isLoading = true
                if (!_appId)
                    throw new Error('_appId is required to list documents')
                if (!_dataType)
                    throw new Error('_dataType is required to list documents')

                const params = {
                    _appId,
                    _dataType,
                    filter: _filter.value,
                }
                if (proxyId) params.proxyId = proxyId
                if (sorting.value?.length) params.sorting = [...sorting.value]
                if (_offset) params.offset = _offset
                if (pageSize) params.pageSize = pageSize

                return store
                    .dispatch(operations.listDocuments, params)
                    .then(({ data }) => {
                        context[`${alias}Page`] = data[_dataType]
                        context.isPristine = true
                        context.isLoaded = true

                        return data
                    })
                    .catch((error) => {
                        console.log(
                            487,
                            'load document page error',
                            _appId,
                            _dataType,
                            _filter,
                            _offset,
                            error
                        )
                        context.error = error
                    })
                    .finally(() => {
                        context.isLoading = false
                    })
            } else if (!autoLoad) {
                if (debug.list) console.log(`noAutoLoad ${_dataType}`, autoLoad)
                const repo = Object.values(store.state.docs[_dataType])
                filterDocuments({
                    _appId,
                    _dataType,
                    documents,
                    repo,
                    filter: _filter,
                    sorting: sorting.value,
                })
                context.isLoaded = true
            }
        } catch (error) {
            context.error = error
            const componentName = getCurrentInstance()?.type?.name
            console.warn(487, componentName, error)
            throw error
        } finally {
            context.isLoading = false
        }
    }

    const listDocuments = async () => {
        context.isWaitingForFilter = false
        try {
            offset = ingestOffset(offset || 0)

            // setup watch event source
            const repository = computed(() =>
                Object.values(store.state.docs?.[_dataType] ?? {})
            )

            watch(
                repository,
                (newV) => {
                    filterDocuments({
                        _appId,
                        _dataType,
                        documents,
                        repo: newV,
                        filter,
                        sorting: sorting.value,
                    })
                },
                { immediate: true }
            )
            watch(
                filter,
                (newV) => {
                    if (!isObject(newV)) return
                    requestDocumentPage({
                        _filter: newV,
                        pageIsOutdated: true,
                    })
                },
                { immediate: true, deep: true } // refresh page on filter updates, and initialize page on load
            )
            watch(offset, (newV, oldV) => {
                console.log(666, newV)
                requestDocumentPage({
                    _offset: newV,
                    pageIsOutdated: true,
                })
            })
            watch(
                sorting,
                (newV) => {
                    filterDocuments({
                        _appId,
                        _dataType,
                        documents,
                        repo: repository.value,
                        filter,
                        sorting: newV,
                    })
                },
                { immediate: true }
            )
        } catch (err) {
            context.error = err
            console.warn(487.2, getCurrentInstance()?.type?.name, err)
            if (process.env.NODE_ENV === 'development') throw err
        }
        await requestDocumentPage()
    }

    onMounted(() => {
        listDocuments().catch((error) => {
            context.error = error
            if (process.env.NODE_ENV === 'development') throw error
        })
    })

    const createDocument = async (properties, _parentId) => {
        properties = ingestProperties(properties)
        if (!filter.value._appId)
            throw new Error('filter._appId is required to create new documents')
        if (!filter.value._dataType)
            throw new Error(
                'filter._dataType is required to create new documents'
            )

        properties._appId = filter?.value?._appId
        properties._dataType = filter?.value?._dataType

        if (_parentId) properties._parentId = _parentId

        try {
            context.isLoading = true
            let generator = generators?.create
            let call
            if (generator) {
                call = generator(properties, _parentId)
            } else {
                call = store.dispatch(operations.createDocument, properties)
            }
            return call
                .then((response) => {
                    try {
                        const { data } = response
                        let { Error } = data
                        const documents = data[properties._dataType]
                        if (documents && !Error) {
                            documents.map((document) =>
                                documents.push(document)
                            )
                        }
                        context.isPristine = true
                    } catch (err) {
                        console.warn(err)
                        throw new Error('Server response is malformed')
                    }
                    return response
                })
                .catch((error) => {
                    console.log(488, error)
                })
        } catch (error) {
            context.error = error
        } finally {
            context.isLoading = false
        }
    }

    return {
        ...toRefs(context),
        documents, // for convenience
        listDocuments,
        createDocument,
    }
}
